import iconPrestamo from './prestamo.svg';
import iconSaldos from './saldoMovimiento.svg';
import iconPortafolio from './iconPortafolio.svg';
import elipseLleno from './elipseLleno.svg';
import elipseVacio from './elipseVacio.svg';
import solicitudAprobada  from './solicitudAprobada.svg';
import rebalanceoInversion from './iconoRebalanceo.svg';
import exitoRebalanceo  from './exitoRebalanceo.svg';


export const ICONS = {
    iconPrestamo,
    iconSaldos,
    iconPortafolio,
    elipseLleno,
    elipseVacio,
    solicitudAprobada,
    rebalanceoInversion,
    exitoRebalanceo
}