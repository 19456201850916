import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router"
import { ICONS } from "../assets/icons";
import paso1Rebalanceo from '../assets/paso1Rebalanceo.svg';
import paso2Rebalanceo from '../assets/paso2Rebalanceo.svg';
import paso3Rebalanceo from '../assets/paso3Rebalanceo.svg';
import { useDispatch, useSelector } from "react-redux";
import {
    obtenerFechaActualString, encryptAES, nWithCommas, capitalizeFirstLetter,
    cambiaEfectivo, agregaAportacion, cambioEfectivo
} from '../utils';
import M from 'materialize-css';
import { BASE_URL } from "../properties";
import { toogleLoad, setTokenSesion, } from "../redux/slices/appslice";
import { getRebalanceoConfig } from "../firestore";




function SolicitudRebalanceo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const appData = useSelector((state) => state.appData)

    const [paso, setPasos] = useState(1);
    const [saldosPorCuenta, setSaldosPorCuenta] = useState(false);
    const [dataSolicitud, setDataSolicitud] = useState({});
    const [valoresRebalanceo, setValoresRebalanceo] = useState({});
    const [rebalanceoValido, setRebalanceoValido] = useState(false);
    const [sumaPerRebalanceo, setSumaPerRebalanceo] = useState(0);
    const [cuentaSelected, setCuentaSelected] = useState("");
    const [configRebalanceo, setConfigRebalanceo] = useState(false);
    const [planes, setPlanes] = useState([]);
    const [cuentaToSend, setCuentaToSend] = useState(false);
    const [muestraError, setMuestraError] = useState('');
    const [valoresFinales, setValoresFinales] = useState(false);
    const [sumasTotales, setSumasTotales]  =  useState({sumaSaldo:0, sumaPorcentaje: 0});

    const [dataPage, setDataPage] = useState({
        titulo: '',
        to_return: '',
        producto: ''
    });

    useEffect(() => {
        if (paso === 3) {
            setValoresFinales(agregarNuevoPorcentaje(planes, valoresRebalanceo));
        }
    }, [paso]);

    useEffect(()=>{
        if(planes.length){
            M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
        }
    },[planes])


    function agregarNuevoPorcentaje(arreglo, objeto) {
        return arreglo.map(item => ({
            ...item,
            nuevoPorcentaje: objeto[item.idPlan] ? parseInt(objeto[item.idPlan], 10) : 0,
        }));
    }

    useEffect(() => {
        if (dataPage.producto !== '' && appData.saldosFecha) {

            for (let saldo of appData.saldosFecha) {
                if (saldo.Producto && saldo.Producto.length) {
                    if (saldo.Producto[0].TipoCliente.toUpperCase() === dataPage.producto) {
                        getConfigRebalanceo(saldo.Producto[0].NombreCliente);
                    }
                }
            }
        }
    }, [dataPage])




    useEffect(() => {
        if (params.tipo && params.tipo === 'plandepensiones') {

            setDataPage({
                titulo: 'Fondo de Pensiones',
                to_return: '/plandepensiones',
                producto: 'FONDO DE PENSIONES'
            });

        } else if (params.tipo && params.tipo === 'cajadeahorro') {

            setDataPage({
                titulo: 'Caja de Ahorro',
                to_return: '/cajadeahorro',
                producto: 'CAJA DE AHORRO'
            });

        } else {
            navigate('/')
        }

    }, [params])


    useEffect(() => {
        rebalanceoValidoF();
    }, [valoresRebalanceo]);

    useEffect(() => {

        if (dataPage.titulo !== "") {
            getSaldosPorCuenta();
        }

    }, [dataPage]);

    const getConfigRebalanceo = async (empresaCliente) => {

        const configRebalanceo = await getRebalanceoConfig();

        if (configRebalanceo[empresaCliente]) {
            setConfigRebalanceo(configRebalanceo[empresaCliente]);
        }
    }



    const buscarProducto = (producto, productos) => {

        const existe = productos.filter(p => p.TipoCliente === producto);

        return existe;
    }



    const getSaldosPorCuenta = async () => {
        dispatch(toogleLoad(true));

        const dataUserProducto = buscarProducto(dataPage.titulo, appData.user.Productos)

        if (dataUserProducto.length && appData.token && appData.tokenSesion) {

            let dataToEncrypt = {
                "Usuario": appData.user.usuarioLogin,
                "IdUsuario": `${appData.user.IdUsuario}`,
                "Sesion": appData.user.Sesion,
                "IdCliente": dataUserProducto[0].IdCliente,
                "IdEmpleado": dataUserProducto[0].IdEmpleado,
                "FechaIni": "20240801",
                "FechaFin": obtenerFechaActualString(),
                "TodaLaHistoria": "True",
                "SistemaOperativo": "web",
                "AppID": "SAF_APP",
                "token": appData.tokenSesion,
                "refresh_token": appData.token.refresh_token,
                "DeviceToken": "dv1"
            }



            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appData.idToken
                },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };

            fetch(`${BASE_URL}/rebalanceos/saldosPorCuenta`, requestOptions)
                .then(response => response.json())
                .then((data) => {

                    if (data.Datos && data.JWT && data.Datos.length) {

                        dispatch(setTokenSesion(data.JWT.current_token))

                        dispatch(toogleLoad(false));

                        setSaldosPorCuenta(data.Datos);

                    } else {
                        dispatch(toogleLoad(false));
                    }
                }).catch((e) => {

                    dispatch(toogleLoad(false));

                });
        }


    }


    const rebalanceoValidoF = async () => {
        //obtiene el tamaño de sados por cuenta.
        setMuestraError(false);
        let totalValores = saldosPorCuenta.length;

        let sumaValores = 0;
        //obtiene la suma total de los valores para rebalanceo
        for (let key of Object.keys(valoresRebalanceo)) {
            sumaValores += parseInt(valoresRebalanceo[key]);
        }


        if (sumaValores > 100) {
            setMuestraError('Verifica que los porcentajes asigandos a tus recursos sumen 100%.');
        }

        setSumaPerRebalanceo(sumaValores);

        if (sumaValores === 100) {
            setRebalanceoValido(true);
        } else {
            setRebalanceoValido(false);
        }
    }

    function mayusFL(text) {
        if (!text) return ''; // Manejar caso de texto vacío o undefined
        return text.charAt(0).toUpperCase() + text.slice(1);
    }


    const generaSolicitud = async () => {
        dispatch(toogleLoad(true));

        const dataUserProducto = buscarProducto(dataPage.titulo, appData.user.Productos)
        if (Object.keys(valoresRebalanceo).length && rebalanceoValido && dataUserProducto.length) {

            let listaDistribucion = [];

            for (let vf of valoresFinales) {

                listaDistribucion.push({
                    IdCuenta: parseInt(cuentaToSend.idCuenta),
                    IdPlan: parseInt(vf.idPlan),
                    Porcentaje: parseInt(vf.nuevoPorcentaje)
                })
            }

            let dataToEncrypt = {
                "Usuario": appData.user.usuarioLogin,
                "IdUsuario": `${appData.user.IdUsuario}`,
                "Sesion": appData.user.Sesion,
                "IdCliente": dataUserProducto[0].IdCliente,
                "IdEmpleado": dataUserProducto[0].IdEmpleado,
                "FechaIni": "20100101",
                "FechaFin": obtenerFechaActualString(),
                "ListaDistribucion": listaDistribucion,
                "DeviceToken": "dv1",
                "SistemaOperativo": "web",
                "AppID": "SAF_APP",
                "token": appData.tokenSesion,
                "refresh_token": appData.token.refresh_token
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appData.idToken
                },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };

            fetch(`${BASE_URL}/rebalanceos/solicitudRebalanceo`, requestOptions)
                .then(response => response.json())
                .then((data) => {

                    if (data.BanderaExito) {

                        dispatch(setTokenSesion(data.JWT.current_token))

                        dispatch(toogleLoad(false));

                        setPasos(4);

                    } else if (data.BanderaExito === 0 && data.Mensaje.trim() !== "") {
                        dispatch(toogleLoad(false));
                        setMuestraError(data.Mensaje);
                    }
                }).catch((e) => {
                    setMuestraError("Ocurrio un error inesperado intente nuevamente.");

                });
        }
    }


    function getTextPaso() {

        if (paso === 1) {
            return "1. Selecciona una cuenta "
        }


        if (paso === 2) {
            return "2. Configura el porcentaje de inversión "
        }

        if (paso === 3) {
            return "3. Confirmación de solicitud"
        }

        return "";
    }

    function searchPlanes(cuentaS) {
        if (configRebalanceo) {
            //Busca que la cuenta exista
            const cuentas = configRebalanceo.cuentas;

            for (let cuenta of cuentas) {
                if (cuenta.descripcion === cuentaS.trim() && location.state) {

                    setCuentaToSend(cuenta);

                    const planesT = agregarSaldo(configRebalanceo.planes, agregarPorcentaje(location.state.aportacionesSeparadas[cuentaS]));

                    calcularSuma(planesT);

                    setPlanes(planesT);
                }
            }
        }
    }

    function agregarPorcentaje(arreglo) {
        // Calcular la suma total de los saldos
        const totalSaldo = arreglo.reduce((sum, item) => sum + item.saldo, 0);

        // Calcular porcentajes iniciales y redondear a enteros
        let porcentajes = arreglo.map(item => Math.floor((item.saldo / totalSaldo) * 100));

        // Ajustar los porcentajes para que sumen exactamente 100
        let sumaPorcentajes = porcentajes.reduce((sum, p) => sum + p, 0);
        let diferencia = 100 - sumaPorcentajes;

        // Aplicar la diferencia distribuyéndola proporcionalmente
        while (diferencia !== 0) {
            for (let i = 0; i < porcentajes.length && diferencia !== 0; i++) {
                porcentajes[i] += diferencia > 0 ? 1 : -1;
                diferencia += diferencia > 0 ? -1 : 1;
            }
        }

        // Agregar el porcentaje ajustado a cada elemento del arreglo
        return arreglo.map((item, index) => ({
            ...item,
            porcentaje: porcentajes[index],
        }));
    }

    function agregarSaldo(arreglo1, arreglo2) {
        return arreglo1.map(item1 => {
            // Buscar en el arreglo2 el objeto cuyo campo fondo coincida con descripcion
            const matchingItem = arreglo2.find(item2 => item2.fondo === item1.descripcion);
            // Si se encuentra coincidencia, agregar el campo saldo; si no, dejar saldo en null
            return { ...item1, saldo: matchingItem ? matchingItem.saldo : null, porcentaje: matchingItem ? matchingItem.porcentaje : null };
        });
    }

    function calcularSuma(arr) {
        let sumaSaldo = 0;
        let sumaPorcentaje = 0;
      
        arr.forEach(item => {
          if (item.saldo !== null && item.saldo !== undefined) {
            sumaSaldo += item.saldo;
          }
          if (item.porcentaje !== null && item.porcentaje !== undefined) {
            sumaPorcentaje += item.porcentaje;
          }
        });
      
        setSumasTotales({ sumaSaldo, sumaPorcentaje });
      }

    return (
        <div className="container mt-2" style={{ minHeight: '90vh' }}>
            <div className="row">

                <div className="col s12 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> <span className="ft-16 txtBold">{dataPage.titulo}</span></p>
                </div>
                <div className="col s4 left-align">
                    <span className="ft-24 boldText">Rebalancear Inversión</span>
                </div>
                <div className="col s4 center-align mt-">
                    <span className="ft-14 ">{getTextPaso()}</span>
                </div>
                <div className="col s4 right-align" style={{ paddingTop: '10px' }}>
                    {
                        paso !== 4 ?
                            <a className="waves-effect waves-light btn ft-14 txt_main-dark-blue white br-12 btnCancel txtBold"
                                onClick={() => {
                                    navigate(dataPage.to_return)
                                }}
                            ><i className="material-icons left ft-14 txt_main-dark-blue">clear</i>Cancelar</a>
                            : null

                    }

                </div>
                <div className="col s12">
                    <div className="row">

                        <div className="col s4"></div>
                        <div className="col s4 center-align">

                            {
                                paso === 1 ?
                                    <img src={paso1Rebalanceo} alt="paso" />
                                    : null
                            }
                            {
                                paso === 2 ?
                                    <img src={paso2Rebalanceo} alt="paso" />
                                    : null
                            }
                            {
                                paso === 3 ?
                                    <img src={paso3Rebalanceo} alt="paso" />
                                    : null
                            }

                        </div>

                        <div className="col s4"></div>
                    </div>
                </div>

                {
                    muestraError ?

                        <div className="col s12">
                            <div style={{
                                background: '#F27468',
                                color: 'white',
                                borderRadius: '8px',
                                padding: '8px 16px 8px 16px',
                                fontWeight: 'bold'

                            }}>
                                <i className="material-icons left">clear</i> {muestraError}
                            </div>
                        </div>


                        : null
                }
            </div>

            <div className="row" hidden={paso !== 1}>
                <div className="col s12">

                    <select className="browser-default"
                        onChange={(e) => {
                            setCuentaSelected(e.target.value);
                            searchPlanes(e.target.value);
                        }}
                    >
                        <option value="">Selecciona una opción</option>
                        {
                            location.state && Object.keys(location.state.aportacionesSeparadas).map((k, i) => {
                                return (
                                    <option value={k}>
                                        {mayusFL(capitalizeFirstLetter(cambioEfectivo(cambiaEfectivo(agregaAportacion(k)))))}
                                    </option>
                                )
                            })
                        }
                    </select>

                </div>

                <div className="col s12 center-align mt-1">
                    <button className="btn btnContinueF5"
                        disabled={cuentaSelected.trim() === ''}
                        onClick={() => {
                            setPasos(2);
                        }}
                    >Continuar</button>
                </div>
            </div>

            <div className="row" hidden={paso !== 2}>
                <div className="col s12">
                    <p className="mb-0">Selecciona un fondo y ajusta el porcentaje de Inversión, <span className="colorDarkBlue boldText">tomando en cuenta que la suma sea 100% de tus recursos.</span></p>
                    <p className="mt-0">Los cambios se reflejarán una vez sean aprobados por el administrador.</p>
                </div>

                <div className="col s12">
                    <table>
                        <thead>
                            <tr className="borderCyan">
                                <th>Fondo</th>
                                <th className="right-align">Saldo Actual</th>
                                <th className="right-align">Porcentaje Actual</th>
                                <th className="right-align">Porcentaje Deseado</th>
                            </tr>
                        </thead>

                        <tbody>

                            {
                                planes.length &&
                                planes.map((f) => {

                                    return (
                                        <tr className="borderCyan" key={f.idPlan}>
                                            <td>{cambioEfectivo(f.descripcion)} 

                                            {cambioEfectivo(f.descripcion).toUpperCase() === 'EFECTIVO' ? 
                                            
                                                <span><i className="material-icons right tooltipped cursorPointer"  
                                                data-position="right" 
                                                data-tooltip="Es dinero de tus aportaciones que no se ha ingresado a los fondos de inversión. Genera rendimientos diarios a la tasa de fondeo."
                                                style={{color:'#969191'}}>info</i></span>
                                        : null}

                                            </td>
                                            <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" value={`$${nWithCommas(parseFloat(f.saldo).toFixed(2))}`} disabled /> </td>
                                            <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" value={`${!f.porcentaje ? '0%' : `${f.porcentaje}%`}`} disabled /></td>
                                            <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" id={f.idPlan}
                                              disabled={cambioEfectivo(f.descripcion).toUpperCase() === 'EFECTIVO'}
                                                onChange={(e) => {
                                                    if (/^\d*$/.test(e.target.value)) {
                                                        let copyValores = { ...valoresRebalanceo };

                                                        copyValores[f.idPlan] = e.target.value;

                                                        setValoresRebalanceo(copyValores)
                                                    }

                                                }}
                                                value={valoresRebalanceo[f.idPlan]} /></td>
                                        </tr>
                                    )
                                })
                            }

                            <tr className="borderCyan" >
                                <td style={{color:'#202A52',fontWeight:'bold'}}>Total</td>
                                <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" value={`$${nWithCommas(parseFloat(sumasTotales.sumaSaldo).toFixed(2))}`} disabled /> </td>
                                <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" value={`${sumasTotales.sumaPorcentaje}%`} disabled /></td>
                                <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" id='totalRebalanceo' value={`${isNaN(sumaPerRebalanceo) ? 0 : sumaPerRebalanceo}%`}  disabled/></td>
                            </tr>




                            <tr>
                                <td colSpan={2}></td>
                                <td><button className="waves-effect waves-teal btn-flat"
                                    onClick={() => {
                                        let copyValores = { ...valoresRebalanceo };

                                        for (let key of Object.keys(copyValores)) {
                                            copyValores[key] = "";
                                        }

                                        setValoresRebalanceo(copyValores);
                                    }}
                                > <i className="material-icons left">clear</i>Descartar Cambios</button></td>
                                <td className="right-align"><button className="btn btnContinueF5" disabled={!rebalanceoValido}
                                    onClick={() => {

                                        if (rebalanceoValido) {
                                            setPasos(3);
                                        }

                                    }}
                                >Continuar</button></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row" hidden={paso !== 3} style={{ paddingLeft: '10%' }}>
                <div className="col s12">
                    <div className="row left-align">
                        <div className="col s12 mt-4">
                            <span style={{ fontWeight: '600', color: '#4D5575' }}>Verifica los porcentajes.</span> Asegúrate de que los porcentajes asignados a tus recursos sean correctos.
                        </div>

                        <div className="col s12 mt-2">
                            <div className="row">
                                {
                                    valoresFinales &&
                                    valoresFinales.map((vf) => {
                                        return (
                                            <div className="col s12">

                                                <div className="row cardVF">

                                                    <div className="col s12 left-align"
                                                        style={{
                                                            fontSize: '20px',
                                                            color: '#4D5575',
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {cambioEfectivo(vf.descripcion)}
                                                    </div>
                                                    <div className="col s12 mt-1">
                                                        <div className="divider" />
                                                    </div>

                                                    <div className="col s6 left-align mt-1">
                                                        Saldo Actual
                                                    </div>
                                                    <div className="col s6 right-align mt-1">
                                                        ${nWithCommas(`${parseFloat(vf.saldo).toFixed(2) || 0.00}`)}
                                                    </div>

                                                    <div className="col s6 left-align cardVFResaltar mt-1">
                                                        Porcentaje Actual
                                                    </div>
                                                    <div className="col s6 right-align cardVFResaltar mt-1">
                                                        {vf.porcentaje || 0}%
                                                    </div>

                                                    <div className="col s6 left-align cardVFResaltar mt-1">
                                                        Porcentaje Deseado
                                                    </div>
                                                    <div className="col s6 right-align cardVFResaltar mt-1">
                                                        {vf.nuevoPorcentaje || 0}%
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className="col s12 " style={{ marginLeft: '15%' }}>
                                    <button className="btn btnContinueF5" disabled={!rebalanceoValido}
                                        onClick={() => {
                                            generaSolicitud();
                                        }}
                                    >Guardar Cambios</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" hidden={paso !== 4}>
                <div className="col s12 center-align">
                    <div className="row">

                        <div className="col s12" style={{
                            fontSize: '24px',
                            fontWeight: '800',
                            color: '#1F3158'
                        }}>
                            ¡Tu solicitud se ha enviado correctamente!
                        </div>
                        <div className="col s12 mt-2">
                            <img src={ICONS.exitoRebalanceo} alt="solicitud aprobada" />
                        </div>

                        <div className="col s12 mt-2">
                            Los cambios se reflejarán una vez que sean aprobados
                        </div>

                        <div className="col s12 mt-2">
                            <button className="btn btnContinueF5"
                                onClick={() => {
                                    navigate(dataPage.to_return)
                                }}
                            >Entendido</button>
                        </div>

                    </div>
                </div>

            </div>

            {/* <div className="row" hidden={paso === 1}>
                <div className="col s12">
                    <div className="row center-align">
                        <div className="col s12 mt-4">
                            <img src={ICONS.solicitudAprobada} alt="solicitud aprobada" />
                        </div>
                        <div className="col s12 ft-24 mt-2 colorDarkBlue">
                            Se ha enviado la solicitud para hacer los cambios

                        </div>
                        <div className="col s12 ft-16 ">

                            <p className="mb-0">Los cambios se verán reflejados en máximo 24 hrs.</p>

                        </div>
                        <div className="col s12 mt-1">
                            <button className="btn btnContinueF5" onClick={() => {
                                navigate(dataPage.to_return)
                            }}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}


export default SolicitudRebalanceo;