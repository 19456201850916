import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import M from 'materialize-css';
import PDF from '../assets/ejemplo.pdf';
import VisorPDF from "../components/visorPdf";

function ConstanciaFiscal() {

    const navigate = useNavigate();

    const [anios, setAnios] = useState([]);
    const [pasos, setPasos] = useState(1);
    const [anioSelected, setAnioSelected] = useState('');
    const [tipoSelected, setTipoSelected] = useState('');

    useEffect(() => {
        obtenerUltimos10Anios();
    }, [])

    useEffect(() => {
        if (anios.length) {
            M.FormSelect.init(document.querySelectorAll('select'), {});
        }


    }, [anios]);


    function obtenerUltimos10Anios() {
        const anioActual = new Date().getFullYear(); // Obtener el año actual
        const anios = [];

        for (let i = 0; i < 10; i++) {
            anios.push(anioActual - i); // Agregar el año correspondiente al arreglo
        }

        setAnios(anios);
    }

    return (

        <div className="container" style={{ minHeight: '100vh' }}>
            <div className="row mt-2">
                <div className="col s12 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate('/cajadeahorro');
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i>Plan de Pensiones</p>
                    <h5 className="txtBold">Constancia Deducible</h5>
                </div>
            </div>

            <form hidden={pasos !== 1} className="row mt-1" onSubmit={(e) => {
                e.preventDefault();
                if (anioSelected !== '' && tipoSelected !== '') {
                    setPasos(2);
                }
            }}>
                <div className="col s12 left-align">
                    <p className="ft-16">Selecciona el año y el formato de descarga.</p>
                </div>

                <div className="input-field col s12 m8">
                    <label>Año de Consulta</label>
                    <select onChange={(e) => {
                        setAnioSelected(e.target.value);
                    }}>
                        <option>Selecciona una opción</option>
                        {
                            anios.map((v) => {
                                return <option value={v} key={v}>{v}</option>
                            })
                        }
                    </select>
                </div>

                <div className="input-field col s12 m8">
                    <label>Formato de Descarga</label>
                    <select onChange={(e) => {
                        setTipoSelected(e.target.value);
                    }}>
                        <option>Selecciona una opción</option>
                        <option value="PDF">PDF</option>
                        <option value="XML">XML</option>
                    </select>
                </div>

                <div className="col s12 m8 left-align mt-1">
                    <button className="btn btnContinueF5"
                        disabled={anioSelected === '' || tipoSelected === ''}
                    >Descargar</button>
                </div>

            </form>

            {
                pasos === 2 && tipoSelected === 'PDF'?
                    <div className="row mt-1" >
                        <embed
                            src={PDF}
                            type="application/pdf"
                            width="100%"
                            height="700"
                            title="Constancia Fiscal"
                        />
                    </div>
                    : null

            }

        </div>
    )

}

export default ConstanciaFiscal;